import { getCookie, setCookie, setCiscoCookie } from './cookie';
export var QueryString = (function () {
    // This function is anonymous, is executed immediately and
    // the return value is assigned to QueryString!
    var query_string = {};
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        // If first entry with this name
        if (typeof query_string[pair[0]] === 'undefined') {
            query_string[pair[0]] = decodeURIComponent(pair[1]);
            // If second entry with this name
        }
        else if (typeof query_string[pair[0]] === 'string') {
            var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
            query_string[pair[0]] = arr;
            // If third or later entry with this name
        }
        else {
            query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }
    return query_string;
}());
export function getLanguage() {
    var language_in_cookie = getCookie('devnet_language');
    var language = navigator.language || navigator.userLanguage;
    return language_in_cookie || language && language.substr(0, 2) || 'en';
}
export function setLanguage(value) {
    return setCiscoCookie('devnet_language', value);
}
export function applyLanguageByUrl() {
    var locale = QueryString.locale;
    if (locale) {
        setLanguage(locale);
    }
}
export function openURL(url, e, action) {
    if (action instanceof Promise) {
        action.then(function () {
            _openURL(url, e);
        });
    }
    else if (typeof action === 'function') {
        var r = action();
        if (r !== false) {
            _openURL(url, e);
        }
    }
    else {
        _openURL(url, e);
    }
    e.preventDefault();
    return false;
}
function _openURL(url, e) {
    window.open(url);
}
