/**
 * Created by aaikepae on 1/27/17.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function getCookies() {
    var str;
    try {
        str = document.cookie;
    }
    catch (err) {
        if (typeof console !== 'undefined' && typeof console.error === 'function') {
            console && console.error(err.stack || err);
        }
        return {};
    }
    return parse(str);
}
export function setCookie(name, value, options) {
    if (options === void 0) { options = {}; }
    //
    deleteCookie(name);
    var str = "".concat(encode(name), "=").concat(encode(value));
    if (value == null) {
        options.maxage = -1;
    }
    if (options.maxage) {
        options.expires = new Date(+new Date() + options.maxage);
    }
    if (options.path)
        str += "; path=".concat(options.path);
    if (options.domain)
        str += "; domain=".concat(options.domain);
    if (options.expires)
        str += "; expires=".concat(options.expires.toUTCString());
    if (options.secure)
        str += '; secure';
    document.cookie = str;
}
export function deleteCookie(name) {
    document.cookie = "".concat(name, "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;");
}
export function getCookie(name) {
    return getCookies()[name];
}
export function setCiscoCookie(name, value, options) {
    if (options === void 0) { options = {}; }
    if (window.location.origin.indexOf("cisco.com")) {
        setCookie(name, value, __assign(__assign({}, options), { path: "/", domain: "cisco.com" }));
    }
    else if (location.origin.indexOf("devnetcloud.com")) {
        setCookie(name, value, __assign(__assign({}, options), { path: "/", domain: "devnetcloud.com" }));
    }
    else {
        setCookie(name, value, __assign(__assign({}, options), { path: "/" }));
    }
}
function parse(str) {
    var obj = {};
    var pairs = str.split(/ *; */);
    var pair;
    if (pairs[0] == '')
        return obj;
    for (var i = 0; i < pairs.length; ++i) {
        pair = pairs[i].split('=');
        obj[decode(pair[0])] = decode(pair[1]);
    }
    return obj;
}
function encode(value) {
    try {
        return encodeURIComponent(value);
    }
    catch (e) {
        console && console.debug('error `encode(%o)` - %o', value, e);
    }
}
function decode(value) {
    try {
        return decodeURIComponent(value);
    }
    catch (e) {
        console && console.console && console.debug('error `decode(%o)` - %o', value, e);
    }
}
